<template>
	<div class="convention-hall page">
		<van-nav-bar class="nav-bar" title="Chọn Phi" />
		<div class="convention-item">
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab title="Danh sách thành phố">
					<div class="card">Nền tảng này có các dịch vụ đi kèm chân thực nhất + Dịch vụ bạn đồng hành thương mại + Niềm đam mê cùng thành phố. Để đảm bảo quyền riêng tư cá nhân của từng người dùng, khách hàng chỉ được tham gia thông qua liên hệ với người tiếp đón hoặc thành viên kỳ cựu của nền tảng này theo đề xuất chính danh.</div>
					<div class="address">
						<van-row type="flex" justify="" gutter="65" v-for="(val, key) in addlist" :key="key">
							<van-col span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div>
				</van-tab>
				<van-tab title="Quy trình và giá cả">
					<div class="card">Nền tảng này có các dịch vụ đi kèm chân thực nhất + Dịch vụ bạn đồng hành thương mại + Niềm đam mê cùng thành phố. Để đảm bảo quyền riêng tư cá nhân của từng người dùng, khách hàng chỉ được tham gia thông qua liên hệ với người tiếp đón hoặc thành viên kỳ cựu của nền tảng này theo đề xuất chính danh.</div>
					<div class="rig-box">
						<p class="rig-title">Có những nguồn tài nguyên nào?</p>
						<p class="rig-content">Người nổi tiếng trên mạng, người mẫu, tiếp viên hàng không, người mẫu trẻ, sinh viên đại học. Chỉ có điều bạn không nghĩ tới, không có gì mà nền tảng này không làm được.</p>
						<p class="rig-title">Phạm vi dịch vụ?</p>
						<p class="rig-content">Hẹn hò miễn phí cùng thành phố, bất kỳ địa điểm nào đều có thể đến toàn quốc, có mặt tại các thành phố tuyến một và tuyến hai trong nước, các thành phố tuyến ba cũng có thể được sắp xếp hẹn qua liên hệ với người tiếp đón.</p>
						<p class="rig-content">Nền tảng này có các dịch vụ đi kèm chân thực nhất + Dịch vụ bạn đồng hành thương mại + Niềm đam mê cùng thành phố. Để đảm bảo quyền riêng tư cá nhân của từng người dùng, khách hàng chỉ được tham gia thông qua liên hệ với người tiếp đón hoặc thành viên kỳ cựu của nền tảng này theo đề xuất chính danh.</p>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			addlist: [
				{
					0: '北京',
					1: '上海',
					2: '广州',
					3: '深圳'
				},
				{
					0: '南京',
					1: '长沙',
					2: '重庆',
					3: '西安'
				}
			]
		};
	},
	methods: {
		addgo(data) {
			if (!localStorage.getItem('token')) {
				this.$router.push({ path: '/Login' });
			} else {
				this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			}
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
			});
		}
	},
	created() {
		this.getAddress();
	}
};
</script>

<style lang="less" scoped>
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
	height: 100px;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #ffffff;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 100px;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
	bottom: 20px;
	background: #f2f2f5;
}
::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #7e5678;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	width: 95%;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #0bdab0;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 20px;
	// margin-top: 10px;
}
.address {
	width: 90%;
	margin: 0 auto;
}
</style>
